import React from "react";
import Routes from "./Routes";
import { motion, useScroll, useSpring } from "framer-motion";

function App() {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });
  // return <Routes />;
  return (
    <>
      <motion.div className="progress-bar" style={{ scaleX }} />
      <Routes />
    </>
  );
}

export default App;
